var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"siteImportModal","data-backdrop":"static","data-keyboard":"false","tabindex":"-1","aria-labelledby":"staticBackdropLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",class:{ 'modal-xl': _vm.formData.length },staticStyle:{"transition":"all 0.3s"}},[_c('div',{staticClass:"modal-content bg-default-dark border-text-default-dark"},[_c('div',{staticClass:"modal-header align-items-center"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Import Site")]),_c('button',{staticClass:"btn btn-dark",attrs:{"disabled":_vm.isDownloading},on:{"click":function($event){return _vm.downloadTemplate()}}},[(_vm.isDownloading)?_c('span',[_c('span',{staticClass:"spinner-border spinner-border-sm mr-2"}),_vm._v(" Downloading... ")]):_c('span',[_c('i',{staticClass:"fas fa-file-download mr-2"}),_vm._v(" Download template ")])])]),_c('div',{staticClass:"modal-body"},[(_vm.selectedFile)?_c('div',{staticClass:"table-responsive"},[(_vm.formData.length)?_c('div',{staticClass:"badge badge-light rounded-pill px-3 py-2 mb-3"},[_vm._v(" ("),_c('sup',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(") is required field ")]):_vm._e(),(_vm.formData.length)?_c('table',{staticClass:"table table-bordered table-sm mb-0"},[_c('thead',{staticClass:"table-dark"},[_c('tr',_vm._l((Object.keys(_vm.formData[0]).filter(
                    (h) => !h.includes('-raw')
                  )),function(header){return _c('th',{key:`formData-header-${header}`,staticClass:"text-capitalize text-nowrap"},[_vm._v(" "+_vm._s(_vm.Helper.splitUnderscore(header))+" "),(_vm.requiredData.includes(header))?_c('sup',{staticClass:"text-danger"},[_vm._v(" * ")]):_vm._e()])}),0)]),_c('tbody',_vm._l((_vm.formData),function(row,rowIndex){return _c('tr',{key:`formData-row-${rowIndex}`},_vm._l((Object.keys(row).filter(
                    (d) => !d.includes('-raw')
                  )),function(data,dataIndex){return _c('td',{key:`formData-data-${dataIndex}`},[_c('div',{staticClass:"p-2",class:{
                      error: _vm.requiredData.includes(data) && !row[data],
                    }},[_vm._v(" "+_vm._s((row[data] && row[data].label) || row[data] || row[`${data}-raw`] || "\ ")+" ")])])}),0)}),0)]):_c('table',{staticClass:"table table-bordered mb-0"},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.selectedFile.name))]),_c('td',{staticClass:"text-center w-1"},[_c('button',{staticClass:"btn btn-danger btn-sm text-nowrap",on:{"click":function($event){_vm.selectedFile = null}}},[_c('i',{staticClass:"fas fa-times mr-1"}),_vm._v(" Remove ")])])])])])]):_c('file-drop',{attrs:{"note":"Accepted format: .xlsx, xls, .csv","accept":_vm.fileAcceptedFormats},on:{"selected":_vm.fileSelected}})],1),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{staticClass:"btn btn-secondary text-light",attrs:{"type":"button","data-bs-dismiss":"modal"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancel ")]),(_vm.selectedFile)?[(!_vm.formData.length)?_c('button',{staticClass:"btn bg-warning",on:{"click":_vm.processFile}},[_vm._v(" Review data "),_c('i',{staticClass:"fas fa-chevron-right ml-2"})]):_c('div',[(_vm.formInvalid)?_c('span',{staticClass:"text-danger small px-3"},[_vm._v(" Please correct rows with red border & import again ")]):_vm._e(),_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":!_vm.formData.length || _vm.formInvalid},on:{"click":_vm.handleSubmit}},[(_vm.formInvalid)?_c('i',{staticClass:"fas fa-exclamation-triangle text-danger mr-2"}):_vm._e(),_vm._v(" Import "+_vm._s(_vm.formData.length)+" sites ")])])]:_vm._e()],2)])]),_c('spinner',{attrs:{"show":_vm.isSaving,"text":`${_vm.isSavingText || 'Creating site...'}`}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"table-dark"},[_c('tr',[_c('th',[_vm._v("File Name")]),_c('th')])])
}]

export { render, staticRenderFns }