<template>
  <div
    id="siteImportModal"
    class="modal fade"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      :class="{ 'modal-xl': formData.length }"
      style="transition: all 0.3s"
    >
      <div class="modal-content bg-default-dark border-text-default-dark">
        <div class="modal-header align-items-center">
          <h5 class="modal-title">Import Site</h5>
          <button
            class="btn btn-dark"
            @click="downloadTemplate()"
            :disabled="isDownloading"
          >
            <span v-if="isDownloading">
              <span class="spinner-border spinner-border-sm mr-2"></span>
              Downloading...
            </span>
            <span v-else>
              <i class="fas fa-file-download mr-2" />
              Download template
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="selectedFile" class="table-responsive">
            <div
              class="badge badge-light rounded-pill px-3 py-2 mb-3"
              v-if="formData.length"
            >
              (<sup class="text-danger">*</sup>) is required field
            </div>
            <table
              class="table table-bordered table-sm mb-0"
              v-if="formData.length"
            >
              <thead class="table-dark">
                <tr>
                  <th
                    v-for="header in Object.keys(formData[0]).filter(
                      (h) => !h.includes('-raw')
                    )"
                    :key="`formData-header-${header}`"
                    class="text-capitalize text-nowrap"
                  >
                    {{ Helper.splitUnderscore(header) }}
                    <sup
                      class="text-danger"
                      v-if="requiredData.includes(header)"
                    >
                      *
                    </sup>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, rowIndex) in formData"
                  :key="`formData-row-${rowIndex}`"
                >
                  <td
                    v-for="(data, dataIndex) in Object.keys(row).filter(
                      (d) => !d.includes('-raw')
                    )"
                    :key="`formData-data-${dataIndex}`"
                  >
                    <div
                      class="p-2"
                      :class="{
                        error: requiredData.includes(data) && !row[data],
                      }"
                    >
                      {{
                        (row[data] && row[data].label) ||
                        row[data] ||
                        row[`${data}-raw`] ||
                        "\&nbsp;"
                      }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table-bordered mb-0" v-else>
              <thead class="table-dark">
                <tr>
                  <th>File Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ selectedFile.name }}</td>
                  <td class="text-center w-1">
                    <button
                      class="btn btn-danger btn-sm text-nowrap"
                      @click="selectedFile = null"
                    >
                      <i class="fas fa-times mr-1" />
                      Remove
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <file-drop
            note="Accepted format: .xlsx, xls, .csv"
            :accept="fileAcceptedFormats"
            @selected="fileSelected"
            v-else
          />
        </div>

        <div class="modal-footer justify-content-between">
          <button
            type="button"
            class="btn btn-secondary text-light"
            data-bs-dismiss="modal"
            @click="closeModal()"
          >
            Cancel
          </button>
          <template v-if="selectedFile">
            <button
              class="btn bg-warning"
              @click="processFile"
              v-if="!formData.length"
            >
              Review data
              <i class="fas fa-chevron-right ml-2" />
            </button>
            <div v-else>
              <span v-if="formInvalid" class="text-danger small px-3">
                Please correct rows with red border &amp; import again
              </span>
              <button
                class="btn btn-primary"
                @click="handleSubmit"
                :disabled="!formData.length || formInvalid"
              >
                <i
                  class="fas fa-exclamation-triangle text-danger mr-2"
                  v-if="formInvalid"
                />
                Import {{ formData.length }} sites
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>

    <spinner :show="isSaving" :text="`${isSavingText || 'Creating site...'}`" />
  </div>
</template>

<script>
import $ from "jquery";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import FileDrop from "@/components/FileDrop";
import Spinner from "@/components/Spinner";
import { isNumber } from "@turf/turf";

export default {
  name: "modal-site-import",
  components: { Spinner, FileDrop },
  data() {
    return {
      types: [],
      regions: [],
      formData: [],
      emptyFormData: {
        name: "",
        site_code: "",
        site_type: null,
        lat: null,
        lng: null,
        region: null,
        permit_date: null,
      },
      requiredData: ["name", "site_type", "region"],
      isSaving: false,
      isSavingText: null,
      selectedFile: null,
      isDownloading: false,
      fileAcceptedFormats: [
        ".csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
    };
  },
  computed: {
    formInvalid() {
      let value = false;

      this.formData.forEach((site) => {
        Object.keys(site).forEach((siteKey) => {
          if (this.requiredData.includes(siteKey) && !site[siteKey]) {
            value = true;
          }
        });
      });

      return value;
    },
  },
  methods: {
    async processFile() {
      try {
        const data = await this.selectedFile.arrayBuffer();

        const workbook = XLSX.read(data);

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const sites = XLSX.utils.sheet_to_json(worksheet);

        if (!sites.length) throw "File is empty";

        sites.forEach((site, siteIndex) => {
          const newSite = {
            no: siteIndex + 1,
            ...JSON.parse(JSON.stringify(this.emptyFormData)),
          };

          Object.keys(site).forEach((key) => {
            const formattedKey = key.toLowerCase().split(" ").join("_");

            if (newSite.hasOwnProperty(formattedKey)) {
              if (formattedKey == "site_type") {
                newSite[formattedKey] =
                  this.types.find(
                    (type) =>
                      type.label.toLowerCase() == site[key]?.toLowerCase()
                  ) || "";
              } else if (["lat", "lng"].includes(formattedKey)) {
                newSite[formattedKey] = isNumber(site[key]) ? site[key] : "";
              } else if (formattedKey == "region") {
                newSite[formattedKey] = this.regions
                  .map((region) => region.label.toLowerCase())
                  .includes(site[key]?.toLowerCase().concat(" region"))
                  ? site[key]
                  : "";
              } else {
                newSite[formattedKey] = site[key];
              }

              if (!newSite[formattedKey]) {
                newSite[`${formattedKey}-raw`] = site[key];
              }
            }
          });

          this.formData.push(newSite);
        });
      } catch (error) {
        console.error(error);

        Swal.fire({
          title: "<h5>Error processing file</h5>",
          html: "<p class='mb-0 text-light'>Please make sure the file is following template format.</p>",
          icon: "error",
        });
      }
    },
    async downloadTemplate() {
      this.isDownloading = true;

      const [template, errTemplate] = await this.Helper.handle(
        this.API.get("site-import-template")
      );
      if (!errTemplate && template.status == 200 && template.data?.file?.url) {
        const templateFetch = await fetch(template.data.file.url);

        const data = await templateFetch.arrayBuffer();

        const workbook = XLSX.read(data);

        XLSX.writeFile(workbook, "Mining_Site_Import_Example.xlsx");
      } else {
        Swal.fire({
          title: "<h5>Error downloading template</h5>",
          icon: "error",
        });
      }

      this.isDownloading = false;
    },
    fileSelected(e) {
      this.selectedFile = e;
    },
    async handleSubmit() {
      if (!this.formInvalid) {
        this.isSaving = true;

        this.isSavingText = `Creating 0/${this.formData.length} sites`;

        let createdSiteIds = [];

        for (let index = 0; index < this.formData.length; index++) {
          try {
            const data = this.formData[index];

            delete data.no;

            data.site_type = data.site_type?.value;

            data.region = this.regions.find(
              (region) =>
                region.label.toLowerCase() ==
                data.region?.toLowerCase().concat(" region")
            )?.value;

            this.isSavingText = `Creating ${index + 1}/${
              this.formData.length
            } sites`;

            const [site, errSite] = await this.Helper.handle(
              this.API.post("sites", data)
            );

            if (!errSite && site.status == 200) {
              createdSiteIds.push(site.data.id);
            } else {
              throw errSite;
            }
          } catch (error) {
            await Promise.all(
              createdSiteIds.map(async (id) => {
                return await this.Helper.handle(this.API.del(`sites/${id}`));
              })
            );

            this.isSavingText = "Error";

            Swal.fire({
              title: "<h5>Error</h5>",
              html: `<div class='line-height-md text-light'>${error}</div>`,
              icon: "error",
            });

            break;
          }
        }

        if (this.isSavingText !== "Error") {
          Swal.fire({
            title: `<h5>${this.formData.length} sites created</h5>`,
            icon: "success",
          }).then(() => {
            this.closeModal(true);
          });
        }

        this.isSaving = false;

        this.isSavingText = null;
      }
    },
    closeModal(reload) {
      this.$emit("close");

      if (reload) {
        this.$emit("reload");
      }
    },
    async initData() {
      const [regions, errRegions] = await this.Helper.handle(
        this.API.get("regions")
      );
      if (!errRegions && regions.status == 200) {
        this.regions = regions.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
          };
        });
      }

      const [types, errTypes] = await this.Helper.handle(
        this.API.get("site-types")
      );
      if (!errTypes && types.status == 200) {
        this.types = types.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
          };
        });
      }
    },
  },
  mounted() {
    this.initData();

    const vm = this;

    $("#siteImportModal").on("hidden.bs.modal", function (event) {
      vm.formData = [];

      vm.selectedFile = null;
    });
  },
  beforeDestroy() {
    $("siteImportModal").off("hidden.bs.modal");
  },
};
</script>

<style scoped>
.error .vs__search::placeholder,
.error .vs__dropdown-toggle,
.error .vs__dropdown-menu {
  border-color: #ed1f23 !important;
  color: #394066;
}
</style>