<template>
  <div
    id="siteModal"
    class="modal fade"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content bg-default-dark border-text-default-dark">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ selectedSite ? "Edit" : "Create New" }} Site
          </h5>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <div
              class="alert alert-danger"
              v-for="key in Object.keys(errors)"
              :key="`error-${key}`"
            >
              <span class="text-capitalize">{{ errors[key] }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>
                  Name
                  <sup class="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.name }"
                  id="Name"
                  v-model="formData.name"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="type"> Contractor </label>
                <v-select
                  v-model="formData.contractor"
                  :options="contractors"
                  :reduce="(contractor) => contractor.value"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>Site Code</label>
                <input
                  type="text"
                  class="form-control"
                  id="SiteCode"
                  v-model="formData.site_code"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="type">
                  Type
                  <sup class="text-danger">*</sup>
                </label>
                <v-select
                  :class="{ 'border border-danger rounded': errors.site_type }"
                  v-model="formData.site_type"
                  :options="types"
                  :reduce="(type) => type.value"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div
                class="form-group clickable"
                @click="showCalendarModal = 'permit_date'"
              >
                <label for="PermitDate">Permit Date Start</label>
                <div class="form-control">
                  <i class="fas fa-calendar-alt mr-2" />
                  {{ formData.permit_date }}
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="form-group clickable"
                @click="showCalendarModal = 'permit_date_end'"
              >
                <label for="PermitDate">Permit Date End</label>
                <div class="form-control">
                  <i class="fas fa-calendar-alt mr-2" />
                  {{ formData.permit_date_end }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div
                class="form-group clickable"
                @click="showCalendarModal = 'luas_permit_date'"
              >
                <label for="PermitDate">LUAS Permit Date</label>
                <div class="form-control">
                  <i class="fas fa-calendar-alt mr-2" />
                  {{ formData.luas_permit_date }}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="region">
                  Region
                  <sup class="text-danger">*</sup>
                </label>
                <v-select
                  :class="{ 'border border-danger rounded': errors.region }"
                  v-model="formData.region"
                  :options="regions"
                  :reduce="(region) => region.value"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label> Latitude </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.lat }"
                  id="Latitude"
                  v-model="formData.lat"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label> Longitude </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.lng }"
                  id="Longitude"
                  v-model="formData.lng"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label> Total Acreage </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.total_acreage }"
                  id="total_acreage"
                  v-model="formData.total_acreage"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label> Reserve </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.reserve_land }"
                  id="reserve_land"
                  v-model="formData.reserve_land"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label> Balance </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.remaining_land }"
                  id="remaining_land"
                  v-model="formData.remaining_land"
                />
              </div>
            </div>
            <div class="col"></div>
          </div>

          <div class="row" v-if="selectedSite">
            <div class="col-12"><label>Attachments</label></div>
            <div
              class="col-12 mb-1"
              v-for="attachment in siteAttachments"
              :key="`file-type-${attachment.key}`"
            >
              <div class="card border-text-default-dark">
                <div class="card-body">
                  <label
                    class="text-uppercase d-flex justify-content-between align-items-center"
                  >
                    {{
                      Helper.splitUnderscore(
                        attachment.key.replace("_file", "")
                      )
                    }}
                    <button
                      class="btn btn-primary btn-sm text-nowrap"
                      @click="showMediaUploader = attachment.key"
                    >
                      <i class="fas fa-file-upload mr-1"></i>
                      Upload
                    </button>
                  </label>
                  <div class="table-responsive">
                    <table class="table table-hover table-striped">
                      <tbody>
                        <tr v-if="!attachment?.files?.length">
                          <td colspan="3" class="text-center">
                            <small>No file</small>
                          </td>
                        </tr>
                        <tr v-for="(file, fileIndex) in attachment.files">
                          <td class="w-1 text-center">{{ fileIndex + 1 }}.</td>
                          <td class="text-uppercase">{{ file.name }}</td>
                          <td class="w-1 text-center text-nowrap px-0">
                            <button
                              class="btn btn-primary btn-sm text-nowrap mr-2"
                              @click="currentFile = file"
                            >
                              <i class="fas fa-file-pdf mr-1"></i>
                              View
                            </button>
                            <button
                              class="btn btn-danger btn-sm text-nowrap"
                              @click="deleteFile(file)"
                            >
                              <i class="fas fa-trash-alt mr-1" />
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="closeModal()"
          >
            Cancel
          </button>
          <button class="btn btn-primary" @click="handleSubmit">
            Save changes
          </button>
        </div>
      </div>
    </div>

    <spinner :show="isSaving" text="Saving site..." />

    <media-preview
      v-if="currentFile"
      :title="`[${Helper.splitUnderscore(
        currentFile.key?.replace('_file', '')
      )?.toUpperCase()}] ${currentFile.name}`"
      :subtitle="selectedSite?.name"
      :media="currentFile"
      :hasNavigation="false"
      @close="currentFile = null"
    />

    <media-uploader
      v-if="selectedSite && showMediaUploader"
      :title="`Upload ${Helper.splitUnderscore(
        showMediaUploader?.toUpperCase()
      )}`"
      :parentId="selectedSite._id"
      parentEndpoint="sites"
      refName="site"
      :refId="selectedSite._id"
      :field="showMediaUploader"
      accept="application/pdf"
      :is-single="false"
      @close="closeMediaUploader"
    />

    <date-picker-modal
      v-if="showCalendarModal"
      :value="formData.permit_date"
      @close="closeCalendarModal"
    />
  </div>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";
import Spinner from "@/components/Spinner";
import DatePickerModal from "@/components/DatePickerModal";
import MediaUploader from "@/components/MediaUploader";
import MediaPreview from "@/components/MediaPreview";

export default {
  name: "modal-site",
  props: ["selectedSite"],
  components: { Spinner, DatePickerModal, MediaUploader, MediaPreview },
  data() {
    return {
      types: [],
      regions: [],
      contractors: [],
      formData: {
        name: "",
        site_code: "",
        site_type: null,
        contractor: null,
        lat: null,
        lng: null,
        region: null,
        permit_date: null,
        permit_date_end: null,
        luas_permit_date: null,
        permit_OR_license_approval_file: [],
        luas_approval_file: [],
        oms_file: [],
        eia_file: [],
        site_report_file: [],
        total_acreage: null,
        reserve_land: null,
        remaining_land: null,
      },
      emptyFormData: {
        name: "",
        site_code: "",
        site_type: null,
        contractor: null,
        lat: null,
        lng: null,
        region: null,
        permit_date: null,
        permit_date_end: null,
        luas_permit_date: null,
        permit_OR_license_approval_file: [],
        luas_approval_file: [],
        oms_file: [],
        eia_file: [],
        site_report_file: [],
        total_acreage: null,
        reserve_land: null,
        remaining_land: null,
      },
      errors: {},
      requiredData: ["name", "site_type", "region"],
      isSaving: false,
      showCalendarModal: false,
      showMediaUploader: false,
      currentFile: null,
    };
  },
  watch: {
    selectedSite: {
      handler() {
        if (this.selectedSite) {
          Object.keys(this.formData).forEach((key) => {
            if (this.selectedSite[key]) {
              if (key.endsWith("_file")) {
                this.formData[key] = this.selectedSite[key];
              } else {
                this.formData[key] =
                  this.selectedSite[key]?.id || this.selectedSite[key];
              }
            }
          });
        } else {
          this.formData = JSON.parse(JSON.stringify(this.emptyFormData));
        }
      },
      deep: true,
    },
  },
  computed: {
    siteAttachments() {
      const attachments = [];

      Object.keys(this.formData).forEach((key) => {
        if (key.endsWith("_file")) {
          attachments.push({
            key: key,
            files: this.formData[key]?.map((file) => {
              file.key = key;

              return file;
            }),
          });
        }
      });

      return attachments;
    },
  },
  methods: {
    async deleteFile(file) {
      Swal.fire({
        title: `<h5 class='mb-0'>Delete ${file.name.toUpperCase()}?</h5>`,
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        confirmButtonColor: "red",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isSaving = true;

          const [call, err] = await this.Helper.handle(
            this.API.del(`upload/files/${file.id}`)
          );

          if (!err && call.status == 200) {
            this.formData[file.key] = null;
          }

          this.isSaving = false;

          Swal.fire("<h5 class='mb-0'>File deleted</h5>", "", "success");

          this.$emit("reload");
        }
      });
    },
    async closeMediaUploader(e) {
      this.showMediaUploader = false;

      if (e && e == "update") {
        this.$emit("reload");
      }
    },
    closeCalendarModal(e) {
      const calendarType = this.showCalendarModal;

      this.showCalendarModal = false;

      if (e !== "close") {
        this.formData[calendarType] = e;
      }
    },
    validate() {
      return new Promise((resolve) => {
        this.errors = {};

        this.$nextTick(() => {
          this.requiredData.forEach((key) => {
            if (!this.formData[key]) {
              this.$set(this.errors, key, `${key} is required`);
            }
          });

          resolve(Object.keys(this.errors).length ? false : true);
        });
      });
    },
    async handleSubmit() {
      const formValidated = await this.validate();

      if (formValidated) {
        this.isSaving = true;

        let method = "post";
        let url = "sites";

        const data = JSON.parse(JSON.stringify(this.formData));

        if (this.selectedSite) {
          method = "put";
          url += `/${this.selectedSite.id}`;

          Object.keys(data)
            .filter((key) => key.includes("_file"))
            .forEach((key) => {
              delete data[key];
            });
        }

        const [site, errSite] = await this.Helper.handle(
          this.API[method](url, data)
        );

        if (!errSite && site.status == 200) {
          this.isSaving = false;

          Swal.fire({
            title: "<h5>Site saved</h5>",
            icon: "success",
          }).then(() => {
            this.closeModal(true);
          });
        } else {
          console.error(errSite);

          this.isSaving = false;

          Swal.fire({
            title: "<h5>Error</h5>",
            html: `<div class='line-height-md'>${errSite}</div>`,
            icon: "error",
          });
        }
      }
    },
    closeModal(reload) {
      this.$emit("close");

      if (reload) {
        this.$emit("reload");
      }
    },
    async initData() {
      const [regions, errRegions] = await this.Helper.handle(
        this.API.get("regions")
      );
      if (!errRegions && regions.status == 200) {
        this.regions = regions.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
          };
        });
      }

      const [contractors, errContractors] = await this.Helper.handle(
        this.API.get("contractors")
      );
      if (!errContractors && contractors.status == 200) {
        this.contractors = contractors.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
          };
        });
      }

      const [types, errTypes] = await this.Helper.handle(
        this.API.get("site-types")
      );
      if (!errTypes && types.status == 200) {
        this.types = types.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
          };
        });
      }
    },
  },
  mounted() {
    this.initData();

    const vm = this;

    $("#siteModal").on("hidden.bs.modal", function (event) {
      vm.errors = {};

      vm.formData = JSON.parse(JSON.stringify(vm.emptyFormData));
    });
  },
  beforeDestroy() {
    $("siteModal").off("hidden.bs.modal");
  },
};
</script>