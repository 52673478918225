<template>
  <div class="view-fullscreen has-navbar has-footer position-relative py-2">
    <div class="d-flex flex-column flex-sm-row justify-content-end align-items-center p-3">
      <div class="input-group mr-sm-3 mb-3 mb-sm-0" style="max-width: 350px">
        <div class="input-group-prepend">
          <span class="input-group-text bg-dark border-secondary">
            <i class="fas fa-search text-secondary" />
          </span>
        </div>
        <input type="text" class="form-control border-secondary" :class="{ 'border-right-0': search.site }"
          placeholder="Search site" v-model="search.site" />
        <div class="input-group-append" v-if="search.site">
          <button class="btn btn-outline-secondary border-left-0" type="button" @click="search.site = ''">
            <i class="fas fa-times" />
          </button>
        </div>
      </div>

      <div class="btn-group">
        <button type="button" class="btn btn-dark" @click="visibleModal = true">
          Create New Site
        </button>
        <button type="button" class="btn bg-dark dropdown-toggle dropdown-toggle-split text-light"
          data-toggle="dropdown" aria-expanded="false">
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item" @click="showImportModal = true">
            <i class="fas fa-file-excel" />
            Import from Excel
          </button>
          <div class="dropdown-divider border-dark"></div>
          <button class="dropdown-item" @click="visibleModal = true">
            <i class="fas fa-plus" />
            Create a single site
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive p-3" v-if="!isLoading && sites.length">
      <table class="table table-bordered table-dark">
        <thead>
          <tr>
            <th class="w-1">No</th>
            <th class="w-1 text-nowrap">Site Code</th>
            <th>Name</th>
            <th>Contractor</th>
            <th class="w-1 text-nowrap">Permit Date</th>
            <th class="w-1 text-nowrap">LUAS Permit Date</th>
            <th class="w-1 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(site, index) in computedSites" :key="site.id">
            <td class="w-1">{{ index + 1 }}</td>
            <td class="w-1">{{ site.site_code }}</td>
            <td>
              <router-link class="text-light" :to="{ name: 'site', params: { id: site.id } }">
                {{ site.name }}
                <i class="far fa-caret-square-right ml-2" />
              </router-link>
              <br />
              <span class="badge bg-text-default-dark badge-pill mr-2 py-1 px-3" v-if="site.lat || site.lng">
                {{ Helper.formatCoordinate([site.lat, site.lng]) }}
              </span>
              <span class="badge bg-dark badge-pill mr-2 py-1 px-3" v-if="site.region">
                {{ site.region && site.region.name.replace(" Region", "") }}
              </span>
              <span class="badge bg-primary badge-pill mr-2 py-1 px-3" v-if="site.site_type">
                {{ site.site_type && site.site_type.name }}
              </span>
            </td>
            <td class="w-1 text-nowrap">
              {{ site.contractor && site.contractor.name }}
            </td>
            <td class="w-1 text-nowrap">
              {{ Helper.formatDate(site.permit_date) }}
              <span v-if="site.permit_date_end"> - {{ Helper.formatDate(site.permit_date_end) }}</span>
            </td>
            <td class="w-1 text-nowrap">
              {{ Helper.formatDate(site.luas_permit_date) }}
            </td>
            <td class="text-center text-nowrap align-middle w-1">
              <!-- <button
                type="button"
                class="btn btn-primary btn-sm mr-2"
                @click="viewSales(site)"
              >
                <i class="fas fa-chart-bar mr-1" />
                Sales
              </button> -->
              <button type="button" class="btn btn-dark btn-sm mr-2" @click="selectedSite = site">
                <i class="fas fa-edit mr-1" />
                Edit
              </button>
              <button type="button" class="btn btn-danger btn-sm" @click="deleteSite(site)">
                <i class="fas fa-trash-alt mr-1" />
                Delete
              </button>
            </td>
          </tr>
          <tr v-if="!computedSites.length">
            <td colspan="6" class="text-center font-italic text-secondary">
              No site found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else-if="isLoading" class="card m-3">
      <div class="card-body d-flex justify-content-center align-items-center">
        <div class="spinner-border" role="status"></div>
        <span class="ml-3 font-weight-bold h5 mb-0">Loading...</span>
      </div>
    </div>
    <div v-else class="card m-3">
      <div class="card-body text-center">No Content</div>
    </div>
    <site-modal @close="visibleModal = false" :selectedSite="selectedSite" @reload="initData()" />
    <site-import-modal @close="showImportModal = false" @reload="initData()" />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import SiteModal from "@/components/SiteModal.vue";
import SiteImportModal from "@/components/SiteImportModal.vue";

export default {
  components: { SiteModal, SiteImportModal },
  name: "site-management-page",
  data() {
    return {
      sites: [],
      isLoading: false,
      selectedSite: null,
      visibleModal: false,
      showImportModal: false,
      search: {
        site: "",
      },
    };
  },
  watch: {
    selectedSite: {
      handler() {
        if (this.selectedSite) this.visibleModal = true;
      },
    },
    visibleModal: {
      handler() {
        if (this.visibleModal) return this.Helper.showModal("#siteModal");
        else {
          this.Helper.hideModal("#siteModal");
          this.selectedSite = null;
        }
      },
    },
    showImportModal: {
      handler() {
        if (this.showImportModal)
          return this.Helper.showModal("#siteImportModal");
        else {
          this.Helper.hideModal("#siteImportModal");
        }
      },
    },
  },
  computed: {
    computedSites() {
      return this.search.site
        ? this.sites.filter(
          (site) =>
            site.name
              ?.toLowerCase()
              .includes(this.search.site?.toLowerCase()) ||
            site.site_code
              ?.toLowerCase()
              .includes(this.search.site?.toLowerCase()) ||
            site.region?.name
              .toLowerCase()
              .includes(this.search.site?.toLowerCase())
        )
        : this.sites;
    },
  },
  methods: {
    viewSales(site) {
      this.$router.push({
        name: "site-sales",
        params: { id: site.id },
      });
    },
    deleteSite(site) {
      Swal.fire({
        title: "<h5>Delete this site?</h5>",
        html: `<div class='line-height-md text-light font-weight-bold'>${site.name}</div>`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Delete",
        reverseButtons: true,
        confirmButtonColor: "red",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const [call, err] = await this.Helper.handle(
            this.API.del(`sites/${site.id}`)
          );

          if (!err && call.status == 200) {
            Swal.fire({
              title: "<h5>Site deleted</h5>",
              icon: "success",
            }).then(() => {
              this.sites = this.sites.filter((_site) => _site.id !== site.id);
            });
          } else {
            Swal.fire({
              title: "<h5>Delete site error</h5>",
              icon: "error",
            });
          }
        }
      });
    },
    async initData() {
      this.isLoading = true;
      const [call, err] = await this.Helper.handle(this.API.get(`sites`));

      if (!err && call.status == 200) {
        this.sites = call.data;

        if (this.selectedSite) {
          this.selectedSite = this.sites.find(site => site._id == this.selectedSite._id);
        }

        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.initData();
  },
};
</script>